import { Injectable } from '@angular/core';

import { Subject, Subscription } from 'rxjs';

import { Deferred } from '@housekeep/infra';

import { environment } from 'environment/environment';

import { AppZendeskAuthService } from 'services/chat-auth-service';
import { InfrastructureService } from 'services/infrastructure-service';
import { ToastService } from 'services/toast-service';

import { WindowWithZendeskWebWidget, ZendeskFieldValue, ZendeskLoginResult } from 'types/zendesk-web-widget';

import { addScript } from 'util/dom';

import { FEATURE_ZENDESK_WEB_WIDGET } from 'var/features';

@Injectable({ providedIn: 'root' })
export class ZendeskWebWidgetService {
  private initialized = false;
  private isReadyDeferred: Deferred<void> = new Deferred();
  private isWidgetOpen = false;
  private onCloseSubject: Subject<void> = new Subject();
  private onOpenSubject: Subject<void> = new Subject();
  private onUnreadMessagesSubject: Subject<number> = new Subject();
  private window = window as unknown as WindowWithZendeskWebWidget;

  constructor(
    private appZendeskAuthService: AppZendeskAuthService,
    private infrastructureService: InfrastructureService,
    private toastService: ToastService
  ) {}

  /**
   * Load the Zendesk Web Widget script and subscribe to events.
   * This only needs to be called once.
   */
  public initialize(): Promise<void> {
    if (!this.initialized) {
      const src = `https://static.zdassets.com/ekr/snippet.js?key=${environment.ZENDESK_PUBLIC_WIDGET_KEY}`;

      addScript(src, 'ze-snippet', async () => {
        this.window.zE('messenger:on', 'close', () => this.onCloseSubject.next());
        this.window.zE('messenger:on', 'open', () => this.onOpenSubject.next());
        this.window.zE('messenger:on', 'unreadMessages', (count: number) => this.onUnreadMessagesSubject.next(count));
        this.window.zE('messenger', 'hide');
        this.onOpenSubject.subscribe(() => (this.isWidgetOpen = true));
        this.onCloseSubject.subscribe(async () => {
          this.isWidgetOpen = false;
          this.window.zE('messenger', 'hide');
          await this.toastService.showInCSChatToast(true);
        });

        this.initialized = true;
        this.isReadyDeferred.resolve();
      });
    }

    return this.isReadyDeferred.promise;
  }

  /**
   * Show and open the Web Widget, dismissing the persistent chat toast if visible.
   * This should be used to return an already-authenticated user to the already-initialized widget.
   */
  public async showAndOpen(): Promise<void> {
    await this.show();
    await this.open();
    await Promise.all([this.toastService.dismissInCSChatToast(), this.toastService.dismissInCSChatWithMessageToast()]);
  }

  public async endChat(): Promise<void> {
    await this.close();
    await Promise.all([this.toastService.dismissInCSChatToast(), this.toastService.dismissInCSChatWithMessageToast()]);
  }

  /**
   * Returns a promise resolving to `true` if the feature switch for the Zendesk Web Widget is active,
   * and `false` if it is inactive.
   */
  public isFeatureActive(): Promise<boolean> {
    return this.infrastructureService.isFeatureActive(FEATURE_ZENDESK_WEB_WIDGET).catch(() => false);
  }

  /**
   * Returns `true` if the Zendesk Web Widget is already loaded on the page, and `false` otherwise.
   */
  public isInitialized(): boolean {
    return this.initialized;
  }

  /**
   * Returns `true` if the Web Widget is currently open, and `false` otherwise.
   */
  public isOpen(): boolean {
    return this.isWidgetOpen;
  }

  /**
   * Returns a promise that resolves when the Web Widget script has loaded.
   */
  public ready(): Promise<void> {
    return this.isReadyDeferred.promise;
  }

  /**
   * Closes the Web Widget if it is open.
   */
  public async close(): Promise<void> {
    await this.ready();
    this.window.zE('messenger', 'close');
  }

  /**
   * Hides the Web Widget launcher.
   */
  public async hide(): Promise<void> {
    await this.ready();
    this.window.zE('messenger', 'hide');
  }

  /**
   * Authenticates the user.
   */
  public async login(): Promise<ZendeskLoginResult> {
    await this.ready();
    const loginDeferred = new Deferred<ZendeskLoginResult>();
    this.window.zE(
      'messenger',
      'loginUser',
      async callback => {
        try {
          callback(await this.appZendeskAuthService.getToken(true));
        } catch (error) {
          loginDeferred.reject(error);
        }
      },
      (result: ZendeskLoginResult) => {
        loginDeferred.resolve(result);
      }
    );
    return loginDeferred.promise;
  }

  /**
   * Logs out the user.
   */
  public async logout(): Promise<void> {
    await this.ready();
    this.window.zE('messenger', 'logoutUser');
  }

  /**
   * Opens the Web Widget.
   */
  public async open(): Promise<void> {
    await this.ready();
    this.window.zE('messenger', 'open');
  }

  /**
   * Sets one or more Zendesk field values on the current conversation.
   */
  public async setFields(fields: ZendeskFieldValue[]): Promise<void> {
    await this.ready();
    const setFieldsDeferred = new Deferred<void>();
    this.window.zE('messenger:set', 'conversationFields', fields, () => {
      setFieldsDeferred.resolve();
    });
    return setFieldsDeferred.promise;
  }

  /**
   * Sets one or more tags on the current conversation.
   */
  public async setTags(tags: string[]): Promise<void> {
    await this.ready();
    this.window.zE('messenger:set', 'conversationTags', tags);
  }

  /**
   * Shows the Web Widget launcher.
   */
  public async show(): Promise<void> {
    await this.ready();
    this.window.zE('messenger', 'show');
  }

  /**
   * Subscribes to events emitted by the Web Widget when it is closed.
   */
  public subscribeToCloseEvents(next: () => void): Subscription {
    return this.onCloseSubject.subscribe(next);
  }

  /**
   * Subscribes to events emitted by the Web Widget when it is opened.
   */
  public subscribeToOpenEvents(next: () => void): Subscription {
    return this.onOpenSubject.subscribe(next);
  }

  /**
   * Subscribes to changes in the number of unread messages in the Web Widget.
   */
  public subscribeToUnreadMessages(next: (count: number) => void): Subscription {
    return this.onUnreadMessagesSubject.subscribe(next);
  }
}
