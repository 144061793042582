export function addScript(src: string, id?: string, onload?: () => void): HTMLScriptElement {
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', src);
  if (id) {
    script.setAttribute('id', id);
  }
  if (onload) {
    script.onload = onload;
  }
  document.body.appendChild(script);
  return script;
}
