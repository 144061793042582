<ion-card class="ion-margin-vertical">
  <ion-card-header class="pad-b-0">
    <ion-card-title>New Customers</ion-card-title>
  </ion-card-header>
  <ion-card-content>
    <ng-container *ngIf="isCurrentlyActive">
      <p *ngIf="isDesiredActive; else activeToNNA" class="mar-y-5">
        New customers can book in with you during your regular working hours.
      </p>
    </ng-container>
    <ng-template #activeToNNA>
      <p class="mar-y-5">
        You will be
        <span class="text--danger text--bold">closed</span>
        to new customers. Only your existing customers will able to book with you.
      </p>
    </ng-template>

    <ng-container *ngIf="isCurrentlyNNA">
      <ng-container *ngIf="isDesiredNNA; else nnaToActive">
        <p class="mar-y-5">
          You are
          <span class="text--danger text--bold">closed</span>
          to new customers. Only your existing customers are able to book with you.
        </p>
        <hk-alert
          type="danger"
          icon="exclamation-mark-circle"
          extraClasses="mar-t-10 mar-b-5"
        >
          <p>
            You could get
            <strong>more jobs</strong>
            and
            <strong>earn more money every week</strong>
            by being open to new customers.
          </p>
        </hk-alert>
      </ng-container>
      <ng-template #nnaToActive>
        <p class="mar-y-5">
          You will be
          <span class="text--bold">open</span>
          to new customers. They can book in with you during your regular hours.
        </p>
        <hk-alert type="info" icon="info-circle" extraClasses="mar-t-10 mar-b-5">
          <p>
            You will get
            <strong>more jobs</strong>
            and
            <strong>earn more money every week</strong>
            by being open to new customers.
          </p>
        </hk-alert>
      </ng-template>
    </ng-container>

    <!-- Worker Status Toggle -->
    <ion-grid class="pad-0 pad-t-10 ion-text-center">
      <ion-row class="negative-margin">
        <ion-col size="7.5" class="pad-0"></ion-col>
        <ion-col size="4.5" class="pad-0">
          <ion-badge color="success">Best</ion-badge>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col class="pad-0" size="4.5">
          <p class="mar-0">No new customers</p>
        </ion-col>
        <ion-col class="pad-y-0" size="3">
          <ion-toggle
            color="success"
            [(ngModel)]="selectedIsActive"
            (ionChange)="onToggleChange($event)"
          ></ion-toggle>
        </ion-col>
        <ion-col class="pad-0" size="4.5">
          <p class="mar-0">New customers</p>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="isCurrentlyActive && isDesiredNNA">
      <ion-item class="item--no-padding item--input-border ion-margin-top">
        <ion-label position="stacked">Why do you want to make this change?</ion-label>
        <ion-select
          interface="action-sheet"
          [disabled]="isSubmitting"
          [(ngModel)]="currentNNAReason"
          required
        >
          <ion-select-option
            *ngFor="let reason of NNA_CHANGE_REASON_CHOICES"
            [value]="reason.key"
          >
            {{ reason.displayName }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <hk-alert
        type="danger"
        icon="exclamation-mark-circle"
        extraClasses="mar-y-10"
        *ngIf="currentNNAReason && currentNNAReason !== NNA_REASON.WantsToLeave"
      >
        <p>
          Selecting this option will mean that you get
          <strong>fewer jobs</strong>
          and
          <strong>earn less money each week.</strong>
        </p>
      </hk-alert>
    </ng-container>

    <form
      #contactForm="ngForm"
      (submit)="handleUpdateNNA()"
      class="form form--item-no-padding"
    >
      <ng-container
        *ngIf="!selectedIsActive && currentNNAReason === NNA_REASON.WantsToLeave"
      >
        <p class="mar-b-10">
          We are sorry that you want to leave Housekeep. Please let us know why.
        </p>

        <ion-item class="item--no-padding item--input-border">
          <ion-label position="stacked">Reason for wanting to leave</ion-label>
          <ion-select
            interface="action-sheet"
            placeholder="Select a category"
            name="subcategory"
            [disabled]="isSubmitting"
            [(ngModel)]="formData.reason"
            required
          >
            <ion-select-option
              *ngFor="let reason of LEAVE_REASON_SUBCATEGORIES"
              [value]="reason.key"
            >
              {{ reason.displayName }}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item class="item--no-padding item--input-border">
          <ion-label position="stacked">
            When would you like to leave Housekeep?
          </ion-label>
          <ion-datetime-button datetime="leaveDate">
            <span slot="date-target" class="text--muted" *ngIf="!formData.startDate">
              Select date
            </span>
          </ion-datetime-button>
          <ion-popover
            [keepContentsMounted]="true"
            [class.center]="deviceService.shouldCenterPopover()"
          >
            <ng-template>
              <ion-datetime
                id="leaveDate"
                name="leaveDate"
                presentation="date"
                [min]="TODAY_STR"
                color="primary"
                [disabled]="isSubmitting"
                [(ngModel)]="formData.startDate"
                [firstDayOfWeek]="1"
                required
              ></ion-datetime>
            </ng-template>
          </ion-popover>
        </ion-item>
      </ng-container>

      <ion-item
        class="item--no-padding item--input-border"
        *ngIf="
          !selectedIsActive &&
          (currentNNAReason === NNA_REASON.WantsToLeave ||
            currentNNAReason === NNA_REASON.Other)
        "
      >
        <ion-label position="stacked">Message</ion-label>
        <ion-textarea
          name="description"
          rows="6"
          [(ngModel)]="formData.description"
          [disabled]="isSubmitting"
          [placeholder]="
            currentNNAReason === NNA_REASON.WantsToLeave
              ? WANTS_TO_LEAVE_PLACEHOLDER_MESSAGE
              : OTHER_PLACEHOLDER_MESSAGE
          "
          [required]="true"
        ></ion-textarea>
      </ion-item>

      <ion-item
        class="item--no-padding item--input-border mar-b-0"
        *ngIf="
          currentNNAReason &&
          currentNNAReason !== NNA_REASON.WantsToLeave &&
          isCurrentlyActive &&
          isDesiredNNA
        "
      >
        <ion-checkbox
          name="confirmed"
          [(ngModel)]="formData.confirmed"
          slot="start"
        ></ion-checkbox>
        <ion-label class="text--light mar-0">
          I confirm that I only want my existing customers
        </ion-label>
      </ion-item>

      <ion-button
        class="mar-t-15"
        expand="block"
        type="submit"
        [disabled]="shouldDisableSubmitButton(contactForm)"
        *ngIf="displayConfirmButton"
      >
        Confirm
      </ion-button>
    </form>
  </ion-card-content>
</ion-card>
