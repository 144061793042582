import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { noop } from 'lodash-es';

import { ZendeskChatService, ZendeskReplyMonitor } from '@housekeep/hk-chat-client';
import { Deferred } from '@housekeep/infra';

import { ToastService } from './toast-service';
import { UserService } from './user-service';

@Injectable({ providedIn: 'root' })
export class AppZendeskChatService {
  /**
   * Subject that broadcasts whenever a chat-related toast is tapped.
   */
  public replyMonitor: ZendeskReplyMonitor | null = null;

  private liveChatAlwaysAvailable = false;
  private persistentToast: HTMLIonToastElement | null = null;
  private readyDeferred: Deferred<void> = new Deferred();

  constructor(
    public chatService: ZendeskChatService,
    private platform: Platform,
    private toastService: ToastService,
    private userService: UserService
  ) {}

  public async initialize(): Promise<void> {
    // On app startup, check whether there is a chat in progress.
    await this.platform.ready();

    try {
      await this.userService.getUser();
      await this.checkOngoingChat();
    } catch (error) {
      /* no-op */
    }

    // On app resume, if we are monitoring replies, make sure the
    // connection is operational by reconnecting.
    this.platform.resume.subscribe(async () => {
      try {
        await this.userService.getUser();
        if (this.isMonitoringReplies) {
          this.startMonitoringReplies();
        }
      } catch (error) {
        /* no-op */
      }
    });

    this.readyDeferred.resolve();
  }

  /**
   * Returns a promise that resolves when the service is ready.
   */
  public ready(): Promise<void> {
    return this.readyDeferred.promise;
  }

  public setLiveChatAlwaysAvailable(liveChatAlwaysAvailable: boolean): void {
    this.liveChatAlwaysAvailable = liveChatAlwaysAvailable;
  }

  public async checkOngoingChat(): Promise<void> {
    try {
      const isOngoing = await this.chatService.chatIsOngoing();
      if (isOngoing) {
        this.startMonitoringReplies();
      } else {
        // If no chat is ongoing, kill the websocket connection.
        this.chatService.disconnect();
      }
    } catch (error) {
      // Failing to check ongoing chat is non-fatal. Swallow the error.
    }
  }

  /**
   * Check with Zendesk chat whether anyone is available to chat.
   *
   * It is necessary to set up a websocket connection via the chat
   * service in order to answer this question. This connection should
   * normally be disposed of. The exception is if this service is
   * in "monitoring replies" state, so that monitoring can continue.
   *
   * Note that this method is used both for legacy Zendesk Chat and also
   * for its replacement, Zendesk Messaging. A feature switch can be used
   * to bypass the check such that live chat is always available.
   */
  public async customerServiceIsOnline(): Promise<boolean> {
    if (this.liveChatAlwaysAvailable) {
      return true;
    }

    try {
      const isOnline = await this.chatService.accountIsOnline();
      // Only maintain the connection if it's being used
      if (!this.isMonitoringReplies) {
        try {
          this.chatService.disconnect();
        } catch (err) {
          // ignore
        }
      }
      return isOnline;
    } catch (err) {
      return false;
    }
  }

  /**
   * Is this service currently monitoring replies?
   */
  public get isMonitoringReplies(): boolean {
    return this.replyMonitor !== null;
  }

  /**
   * Stop monitoring and tear down the monitor.
   */
  public stopMonitoringReplies(): void {
    if (this.isMonitoringReplies) {
      this.replyMonitor.tearDown();
      this.replyMonitor = null;
    }

    // Disable redirect before dismissing.
    this.disableInCSChatToastRedirect();

    // Dismissing when toast is not shown is consequence-free, so we
    // blindly dismiss both.
    this.toastService.dismissInCSChatToast();
    this.toastService.dismissInCSChatWithMessageToast();
  }

  /**
   * Set up a new reply monitor and start monitoring.
   */
  public startMonitoringReplies(): void {
    this.stopMonitoringReplies();

    const toastCallback = (toast: HTMLIonToastElement) => {
      // Keep a reference to the current persistent toast:
      this.persistentToast = toast;
    };

    // Show the persistent "you're in a chat" reminder.
    this.toastService.showInCSChatToast().then(toastCallback);

    // On receiving a reply, switch to the toast indicating there's a message.
    this.chatService
      .getReplyMonitor()
      .then((replyMonitor: ZendeskReplyMonitor) => {
        this.replyMonitor = replyMonitor;
        replyMonitor.replyReceived.subscribe(() => {
          // Displaying new persistent toast dismisses the existing one. We don't
          // want to navigate to the chat on dismiss, so disable the callback.
          this.disableInCSChatToastRedirect();

          this.toastService.showInCSChatWithMessageToast().then(toastCallback);
        });

        // On receiving notification that agent has left, inform user.
        replyMonitor.agentLeft.subscribe(() => {
          this.stopMonitoringReplies();
          this.toastService.showToast({
            message: 'Housekeep ended the chat',
            duration: 5000
          });
        });
      })
      .catch(() => {
        // getReplyMonitor could possibly fail. The best we can do is to
        // put things back in a consistent state.
        this.replyMonitor = null;
        this.disableInCSChatToastRedirect();
        this.toastService.dismissInCSChatToast();
        this.toastService.dismissInCSChatWithMessageToast();
      });
  }

  /**
   * Visitor has explicitly said they no longer want to chat.
   */
  public visitorLeftChatPermanently(): Promise<void> {
    // Swallow error so that user can leave chat anyway.
    return this.chatService.endChat().catch(noop);
  }

  /**
   * Visitor has left without explicitly saying they've finished.
   */
  public visitorLeftChatTemporarily() {
    this.startMonitoringReplies();
  }

  private disableInCSChatToastRedirect(): void {
    if (this.persistentToast) {
      this.persistentToast = null;
    }
  }
}
