import { Injectable } from '@angular/core';

import { Position } from '@capacitor/geolocation';

import { Date, dateToStr, Duration, OfficeHours, officeHoursSerializer, toDate, Visit } from '@housekeep/infra';

import { HelpCategoryDefinition, SupportChannel } from 'models/help-category';

import { helpCategoryDefinitionSerializer } from 'serializers/help-category';

import { CacheService } from './cache-service';
import { ErrorService } from './error-service';
import { LocationService } from './location-service';
import { RequestService } from './request-service';
import { SentryService } from './sentry-service';

interface ProblemCategory {
  displayName: string;
  key: string;
}

type CustomerCallReason = Array<string>;

interface CustomerCallReasonChoice {
  displayName: string;
  key: string;
}

interface CustomerCallResponse {
  telephoneNumber: string;
}

interface ContactFormData {
  category: string;
  confirmed?: boolean;
  reason?: string;
  description?: string;
  subcategory?: string;
  startDate?: string;
  removeJobs?: string;
  customerAgreed?: boolean;
  visit?: Visit;
}

interface DurationFormData {
  visit: Visit;
  minutes: number;
  description: string;
  customerAgreed: boolean;
}

interface AddProductsFormData {
  visit: Visit;
  reason: string;
  customerAgreed: boolean;
}

const REQUEST_CUSTOMER_CALL_ENDPOINT = 'contact/request-customer-call/';
const REQUEST_WORKER_CALL_ENDPOINT = 'contact/request-worker-to-worker-call/';
const CUSTOMER_CALL_REASONS_CACHE_KEY = 'CUSTOMER_CALL_REASONS';

// General worker problem categories
const CONTACT_DETAILS = 'contactdetails';
const HOLIDAY = 'holiday';
const HOURS = 'hours';
const POSTCODE = 'postcode';
const HELP_COMPLAINT = 'complaint';
const HELP_KEYS = 'keys';
const HELP_PAYMENT = 'payment';
const HELP_RATINGS_RELIABILITY_FULFILMENT = 'ratings_reliability_fulfilment';
const HELP_MISC_PROBLEM = 'misc_problem';
const HELP_SOMETHING_ELSE = 'other_something_else';
const HELP_WANTS_TO_LEAVE = 'leave_housekeep';
const HELP_RESCHEDULE_JOB = 'reschedule_job';
// NNA form related worker problem categories
const HELP_NNA_WANTS_TO_LEAVE = 'nna_leave_housekeep';
const HELP_NNA_OTHER = 'nna_other';
// Job-related worker problem categories: today
const HELP_JOB_FINISHED = 'finished_job';
const HELP_JOB_TODAY_CLEANING = 'instructions';
const HELP_JOB_TODAY_DAMAGE = 'damage';
const HELP_JOB_TODAY_DURATION = 'duration';
const HELP_JOB_TODAY_EQUIPMENT = 'equipment';
const HELP_JOB_TODAY_LATE = 'job_late';
const HELP_JOB_TODAY_LOST = 'lost';
const HELP_JOB_TODAY_OTHER = 'job_today';
const HELP_JOB_TODAY_PRODUCTS = 'add_products';
const HELP_JOB_TODAY_STUCK = 'stuck';
const HELP_JOB_TODAY_REMOTE_KEY_TRANSFER = 'remote_key_transfer';

// Job-related worker problem categories: later
const HELP_JOB_LATER_OTHER = 'job_future';
const HELP_JOB_LATER_QUESTION = 'question_job_later';
const HELP_JOB_LATER_CLEANING = 'later_cleaning';
const HELP_JOB_LATER_NEED_KEY = 'later_need_key';

// Other category past job
const HELP_JOB_PAST = 'question_job_past';

// Change of hours categories
const INCREASE_WORKING_HOURS = 'increase_hours';
const DECREASE_WORKING_HOURS = 'decrease_hours';

// Rating, Reliability and Fulfilment categories
const CUSTOMER_RATING = 'customer_rating_for_worker';
const MY_RELIABILITY = 'my_reliability';
const MY_FULFILMENT = 'my_fulfilment';

// Change area categories
const ADD_NEW_POSTCODES = 'add_new_postcodes';
const CHANGE_CURRENT_POSTCODES = 'change_current_postcodes';
const CHANGE_MY_ADDRESS = 'change_my_address';
const REMOVE_OLD_POSTCODE_JOBS = 'Remove old jobs';
const DO_NOT_REMOVE_POSTCODE_JOBS = 'Keep old jobs';

// Wanting to leave categories
const LEAVE_FOUND_JOB = 'found_other_job';
const LEAVE_MOVING_AWAY = 'moving_away';
const LEAVE_INJURY_OR_ILLNESS = 'injury_or_illness';
const LEAVE_FAMILY = 'family';
const LEAVE_UNHAPPY_WITH_HOUSEKEEP = 'unhappy_with_housekeep';
const LEAVE_UNHAPPY_WITH_EARNINGS = 'unhappy_with_earnings';
const LEAVE_REGULAR_AVAILABILITY = 'no_regular_availability';
const LEAVE_OTHER = 'other';

// Ordered list of general worker problem categories with display names
const WORKER_PROBLEM_CATEGORIES: ProblemCategory[] = [
  { key: HELP_JOB_TODAY_OTHER, displayName: 'Job today' },
  { key: HELP_JOB_LATER_OTHER, displayName: 'Job later this week' },
  { key: HOLIDAY, displayName: 'Amend time off' },
  { key: CONTACT_DETAILS, displayName: 'Change contact details' },
  { key: HOURS, displayName: 'Change working hours' },
  { key: POSTCODE, displayName: 'Change working areas' },
  { key: HELP_KEYS, displayName: 'Keys' },
  { key: HELP_PAYMENT, displayName: 'Payment' },
  { key: HELP_RATINGS_RELIABILITY_FULFILMENT, displayName: 'Ratings/Reliability/Fulfilment' },
  { key: HELP_WANTS_TO_LEAVE, displayName: 'Leave Housekeep' },
  { key: HELP_MISC_PROBLEM, displayName: 'Other' }
];

// Ordered list of change hour subcategories with display names
const CHANGE_HOURS_SUBCATEGORIES: ProblemCategory[] = [
  { key: INCREASE_WORKING_HOURS, displayName: 'Increase working hours' },
  { key: DECREASE_WORKING_HOURS, displayName: 'Decrease working hours' }
];

// Ordered list of rating, reliability and fulfilment subcategories with display names
const RATING_RELIABILITY_SUBCATEGORIES: ProblemCategory[] = [
  { key: CUSTOMER_RATING, displayName: "A customer's rating" },
  { key: MY_RELIABILITY, displayName: 'My reliability' },
  { key: MY_FULFILMENT, displayName: 'My fulfilment' }
];

// Ordered list of change hour subcategories with display names
const CHANGE_AREA_SUBCATEGORIES: ProblemCategory[] = [
  { key: ADD_NEW_POSTCODES, displayName: 'Add new postcodes' },
  { key: CHANGE_CURRENT_POSTCODES, displayName: 'Change or remove old postcodes' },
  { key: CHANGE_MY_ADDRESS, displayName: 'Change my address' }
];

// Ordered list of 'other' subcategories with display names
const OTHER_SUBCATEGORIES: ProblemCategory[] = [
  { key: HELP_JOB_TODAY_OTHER, displayName: 'A job today' },
  { key: HELP_JOB_LATER_OTHER, displayName: 'A job later this week' },
  { key: HELP_JOB_PAST, displayName: 'A past job' },
  { key: HELP_SOMETHING_ELSE, displayName: 'Something else' }
];

// Ordered list of "I want to leave Housekeep" subcategories with display names
const LEAVE_REASON_SUBCATEGORIES: ProblemCategory[] = [
  { key: LEAVE_FOUND_JOB, displayName: 'Found other job' },
  { key: LEAVE_MOVING_AWAY, displayName: 'Moving away' },
  { key: LEAVE_INJURY_OR_ILLNESS, displayName: 'Injury or illness' },
  { key: LEAVE_FAMILY, displayName: 'Family' },
  { key: LEAVE_UNHAPPY_WITH_HOUSEKEEP, displayName: 'Unhappy with Housekeep experience' },
  { key: LEAVE_UNHAPPY_WITH_EARNINGS, displayName: 'Unhappy with earnings' },
  { key: LEAVE_REGULAR_AVAILABILITY, displayName: 'No regular availability' },
  { key: LEAVE_OTHER, displayName: 'Other (please explain)' }
];

const POSTCODE_CHANGE_JOB_REMOVAL_CHOICE: ProblemCategory[] = [
  { key: REMOVE_OLD_POSTCODE_JOBS, displayName: 'Remove jobs' },
  {
    key: DO_NOT_REMOVE_POSTCODE_JOBS,
    displayName: 'Keep jobs'
  }
];

const FAST_RESPONSE_MESSAGE = 'We have received your request and will reply by phone or email soon.';

const NORMAL_RESPONSE_MESSAGE = 'We have received your request and will reply by email within 1 working day.';

const FAST_RESPONSE_PROBLEM_CATEGORIES = [
  HOLIDAY,
  HELP_JOB_TODAY_DURATION,
  HELP_JOB_TODAY_LATE,
  HELP_JOB_TODAY_LOST,
  HELP_JOB_TODAY_STUCK,
  HELP_JOB_TODAY_REMOTE_KEY_TRANSFER,
  HELP_RESCHEDULE_JOB
];

// Request callback categories:
enum CallRequestCategory {
  ChangeHoursAreas = 'call_request_change_hours_areas',
  Complaint = 'call_request_complaint',
  JobFinishEarly = 'call_request_job_finish_early',
  JobLater = 'call_request_job_later',
  JobToday = 'call_request_job_today',
  Keys = 'call_request_keys',
  Payment = 'call_request_payment',
  RatingsReliabilityFulfilment = 'call_request_ratings_reliability_fulfilment',
  TimeOff = 'call_request_time_off',
  Other = 'call_request_other'
}

const HELP_CALL_REQUEST_CATEGORIES: Map<CallRequestCategory, string> = new Map([
  [CallRequestCategory.JobToday, 'A job today'],
  [CallRequestCategory.Keys, 'Keys'],
  [CallRequestCategory.ChangeHoursAreas, 'Change working hours or areas'],
  [CallRequestCategory.TimeOff, 'Time Off'],
  [CallRequestCategory.Payment, 'Payment'],
  [CallRequestCategory.RatingsReliabilityFulfilment, 'Ratings/Reliability/Fulfilment'],
  [CallRequestCategory.Complaint, 'Complaint'],
  [CallRequestCategory.JobLater, 'A job later this week'],
  [CallRequestCategory.Other, 'Something else']
]);

export enum HelpRequestCallCancelReasons {
  StartedChat = 'chat',
  ManualCancel = 'manual'
}

export const HELP_CALL_REQUEST_DIRECT_CALL = 'hkapp-direct-call';

@Injectable({ providedIn: 'root' })
class HelpService {
  constructor(
    private cacheService: CacheService,
    private errorService: ErrorService,
    private locationService: LocationService,
    private requestService: RequestService,
    private sentryService: SentryService
  ) {}

  /**
   * Requests a callback from Housekeep.
   * If no phone number is specified, the backend will determine the number.
   * @param {CallRequestCategory} [category]
   * @param {string} [phone] The phone number to be called back.
   * @return {Promise}
   */
  public callMe(category: CallRequestCategory | string, description?: string, phone?: string): Promise<any> {
    const data: Record<string, string | CallRequestCategory> = { category, description };

    if (phone) {
      data.phone = phone;
    }

    return this.requestService.post(`contact/request-call/`, data);
  }

  /**
   * Return whether the logged in user has current, open call requests.
   *
   * Callers shouldn't have to handle errors. Assume that there are no
   * call requests if the API errors for any reason.
   *
   * @return {Promise<boolean>}
   */
  public hasOpenCallRequests(): Promise<boolean> {
    return this.requestService
      .get(`contact/open-call-requests/`)
      .then(response => response.hasOpenRequests)
      .catch(err => {
        this.logCallRequestError('Failed to get open call requests', err);
        return false;
      });
  }

  /**
   * Cancel any outstanding call requests.
   *
   * Callers shouldn't have to handle errors, but should be informed
   * that the request was unsuccessful.
   *
   * @return {Promise<boolean>}
   */
  public cancelCallRequests(cancelReason: string): Promise<boolean> {
    return this.requestService
      .post(`contact/cancel-call-requests/`, { cancelReason })
      .then(response => response.success)
      .catch(() => false);
  }

  /**
   * Returns the confirmation message to display after submitting the specified
   * category of request.
   * @param {string} category
   * @return {string}
   */
  public getResponseMessage(category: string) {
    if (FAST_RESPONSE_PROBLEM_CATEGORIES.indexOf(category) !== -1) {
      return FAST_RESPONSE_MESSAGE;
    }

    return NORMAL_RESPONSE_MESSAGE;
  }

  /**
   * Gets the current Housekeep office hours from the server.
   * @return {Promise<OfficeHours>} A promise resolving to an `OfficeHours` instance.
   */
  public getOfficeHours(): Promise<OfficeHours> {
    return this.requestService.getInstance(`contact/office-hours/`, officeHoursSerializer);
  }

  /**
   * Gets the current Housekeep office hours from the server.
   * @return {Promise<OfficeHours>} A promise resolving to an `OfficeHours` instance.
   */
  public getChannelOfficeHours(channel: SupportChannel, date?: Date): Promise<OfficeHours> {
    const dateString = date ? `/${dateToStr(date)}` : '';
    return this.requestService.getInstance(`contact/channel-hours/${channel}${dateString}/`, officeHoursSerializer);
  }

  public getHelpCategoryDefinition(topic: string): Promise<HelpCategoryDefinition> {
    return this.requestService.get('contact/worker-help-category/', {
      params: { categoryName: topic },
      responseSerializer: helpCategoryDefinitionSerializer
    });
  }

  /**
   * Report that the user will be late for the specified visit.
   * The user's location will be sent to Housekeep.
   * @param {Visit} visit The affected visit
   * @param {string} eta The estimated time of arrival as a string e.g. "16:00"
   * @return {Promise}
   */
  public reportLate(visit: Visit, eta: string): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_LATE, visit, true, { eta });
  }

  /**
   * Report that the user is lost on their way to the specified visit.
   * The user's location will be sent to Housekeep.
   * @param {Visit} visit The affected visit
   * @return {Promise}
   */
  public reportLost(visit: Visit): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_LOST, visit, true);
  }

  /**
   * Report that the user is stuck outside the property for the specified visit.
   * The user's location will be sent to Housekeep.
   * @param {Visit} visit The affected visit
   * @return {Promise}
   */
  public reportStuckOutside(visit: Visit): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_STUCK, visit, true);
  }

  /**
   * Report that the user is having a problem with a remote key transfer
   * @param {Visit} visit The affected visit
   * @return {Promise}
   */
  public reportProblemWithRkt(visit: Visit): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_REMOTE_KEY_TRANSFER, visit, true);
  }

  /**
   * Report that the user damaged something during the specified visit.
   * @param {Visit} visit The affected visit
   * @return {Promise}
   */
  public reportDamage(visit: Visit): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_DAMAGE, visit);
  }

  /**
   * Report that the housekeeper needs help with instructions.
   * @param {Visit} visit The affected visit
   * @return {Promise}
   */
  public reportInstructionsProblem(visit: Visit): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_CLEANING, visit);
  }

  /**
   * Report that the housekeeper needs help with equipment.
   * @param {Visit} visit The affected visit
   * @return {Promise}
   */
  public reportEquipmentProblem(visit: Visit): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_EQUIPMENT, visit);
  }

  /**
   * Report that the user needs a key for a job later
   * {Visit} visit The relevant visit
   */
  public reportLaterNeedKey(visit: Visit): Promise<any> {
    return this.problemWithVisit(HELP_JOB_LATER_NEED_KEY, visit);
  }

  /**
   * Submit a help request relating to the time allocated to the a
   * visit.
   * @param {{visit: Visit, minutes: number, description: string}} data
   * @return {Promise}
   */
  public submitHelpWithDurationForm(data: DurationFormData): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_DURATION, data.visit, false, data);
  }

  /**
   * Submit a help request relating to the time allocated to the a
   * visit.
   * @param {{visit: Visit, minutes: number, description: string}} data
   * @return {Promise}
   */
  public submitAddProductsForm(data: AddProductsFormData): Promise<any> {
    return this.problemWithVisit(HELP_JOB_TODAY_PRODUCTS, data.visit, false, data);
  }

  /**
   * Submit a help request relating to rescheduling a visit
   * @param {{visit: Visit, customerAgreed: boolean, description: string}} data
   * @return {Promise}
   */
  public submitRescheduleJobForm(data: { visit: Visit; customerAgreed: boolean; description: string }): Promise<any> {
    return this.problemWithVisit(HELP_RESCHEDULE_JOB, data.visit, false, data);
  }

  /**
   * Submit a help request relating to a visit.
   * @param {{category: string, visit: Visit, description: string}} data
   * @return {Promise}
   */
  public submitHelpWithJobForm(data: { category: string; visit: Visit; description: string }): Promise<any> {
    return this.problemWithVisit(data.category, data.visit, false, data);
  }

  /**
   * Submit the contact form.
   * @param data.startDate A date represented by a string in ISO 8601 format
   */
  public submitContactForm(data: ContactFormData): Promise<any> {
    if (data.subcategory) {
      data.category = data.subcategory;
    }
    if (data.startDate) {
      data.startDate = toDate(data.startDate).format('YYYY-MM-DD');
    }

    return this.requestService.post(`contact/report-problem/`, data);
  }

  /**
   * Check if the specified error is a `service_unavailable` error.
   * @param err
   */
  public isServiceUnavailableError(err: any): boolean {
    return (
      this.errorService.isHttp404NotFoundError(err) && this.errorService.getErrorCode(err) === 'service_unavailable'
    );
  }

  /**
   * Resolve the reasons why a Housekeeper may call their customer.
   */
  public getCustomerCallReasonChoices(): Promise<CustomerCallReasonChoice[]> {
    return this.getCachedCustomerCallReasons()
      .catch(err => {
        if (!this.errorService.isCacheError(err)) {
          // Log the error but don't re-throw it
          this.sentryService.log({
            message: 'Customer call reasons - get cache error'
          });
        }
        return this.getServerCustomerCallReasons();
      })
      .then(reasons =>
        reasons.map(
          // Map array of ['k', 'd'] to {key: 'k', displayName: 'd'}
          ([key, displayName]) => ({ key, displayName })
        )
      );
  }

  /**
   * With the given visit, reason and optional location, request a customer call.
   * If permitted by the server, a telephone number will be returned.
   */
  public requestCustomerCall(visit: Visit, reason: string, location?: Position): Promise<CustomerCallResponse> {
    const data: any = {
      jobId: visit.jobId,
      scheduledDate: visit.scheduledDate.format('YYYY-MM-DD'),
      reason
    };

    if (location) {
      data.location = location.coords;
    }

    return this.requestService.post(REQUEST_CUSTOMER_CALL_ENDPOINT, data);
  }

  public requestWorkerCall(
    visit: Visit,
    remoteKeyTransferId: string,
    reason: string,
    location?: Position
  ): Promise<CustomerCallResponse> {
    const data: any = {
      jobId: visit.jobId,
      scheduledDate: visit.scheduledDate.format('YYYY-MM-DD'),
      remoteKeyTransferId: remoteKeyTransferId,
      reason
    };

    if (location) {
      data.location = location.coords;
    }

    return this.requestService.post(REQUEST_WORKER_CALL_ENDPOINT, data);
  }

  /**
   * Attempt to get a cached list of valid customer call reasons.
   */
  private getCachedCustomerCallReasons(): Promise<CustomerCallReason[]> {
    return this.cacheService.get(CUSTOMER_CALL_REASONS_CACHE_KEY);
  }

  /**
   * Attempt to get a list of valid customer call reasons from the server.
   * @return {Promise<CustomerCallReason[]>}
   */
  private getServerCustomerCallReasons(): Promise<CustomerCallReason[]> {
    return this.requestService.get(REQUEST_CUSTOMER_CALL_ENDPOINT).then(response => {
      const { reasons } = response;
      const duration = new Duration({ days: 1 });

      // Cache the customer call reasons
      this.cacheService.set(CUSTOMER_CALL_REASONS_CACHE_KEY, reasons, { duration }).catch(err => {
        if (!this.errorService.isCacheError(err)) {
          // Log the error but don't re-throw it
          this.sentryService.log({
            message: 'Customer call reasons - get cache error'
          });
        }
      });

      return reasons;
    });
  }

  /**
   * Reports a problem with a visit.
   * The `category` must be one of a set of problem categories recognised by the backend.
   * @param {string} category The problem category
   * @param {Visit} visit The affected visit
   * @param {boolean} location if `true`, the user's location will be sent to Housekeep
   * @param {Object} data Any additional data to send in the request.
   * @return {Promise}
   */
  private problemWithVisit(category: string, visit: Visit, location = false, data = {}) {
    const postData = Object.assign(
      {
        category,
        jobId: visit.jobId,
        actualDate: visit.actualDate.format('YYYY-MM-DD')
      },
      data
    );

    return location
      ? this.postWithLocation(`contact/report-problem/`, postData)
      : this.requestService.post(`contact/report-problem/`, postData);
  }

  /**
   * A wrapper around `RequestService.post` that gets the device location
   * (ignoring any errors if this fails), and adds the latitude, longitude and location
   * accuracy to the POST data.
   *
   * @param {string} path The API endpoint
   * @param {Object} data The POST data to which location information will be added
   * @return {Promise}
   */
  private postWithLocation(path: string, data: any): Promise<any> {
    return this.locationService
      .getLocation()
      .then(
        (position: Position) =>
          Object.assign(data, {
            location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            },
            locationAccuracy: position.coords.accuracy
          }),

        () => {
          // Allow geolocation failure.
        }
      )
      .then(() => this.requestService.post(path, data));
  }

  private logCallRequestError(message, error): void {
    this.sentryService.log({
      message: message,
      level: 'error',
      extras: { error }
    });
  }
}

export {
  HelpService,
  ContactFormData,
  DurationFormData,
  CustomerCallReasonChoice,
  ProblemCategory,
  ADD_NEW_POSTCODES,
  CHANGE_CURRENT_POSTCODES,
  CHANGE_MY_ADDRESS,
  CUSTOMER_CALL_REASONS_CACHE_KEY,
  REQUEST_CUSTOMER_CALL_ENDPOINT,
  REQUEST_WORKER_CALL_ENDPOINT,
  WORKER_PROBLEM_CATEGORIES,
  CHANGE_AREA_SUBCATEGORIES,
  CHANGE_HOURS_SUBCATEGORIES,
  LEAVE_REASON_SUBCATEGORIES,
  CUSTOMER_RATING,
  RATING_RELIABILITY_SUBCATEGORIES,
  CONTACT_DETAILS,
  HELP_COMPLAINT,
  HELP_MISC_PROBLEM,
  HELP_PAYMENT,
  HELP_RATINGS_RELIABILITY_FULFILMENT,
  HOLIDAY,
  HOURS,
  MY_RELIABILITY,
  MY_FULFILMENT,
  OTHER_SUBCATEGORIES,
  POSTCODE_CHANGE_JOB_REMOVAL_CHOICE,
  INCREASE_WORKING_HOURS,
  DECREASE_WORKING_HOURS,
  POSTCODE,
  FAST_RESPONSE_MESSAGE,
  NORMAL_RESPONSE_MESSAGE,
  HELP_JOB_FINISHED,
  HELP_JOB_LATER_OTHER,
  HELP_JOB_LATER_QUESTION,
  HELP_JOB_PAST,
  HELP_JOB_TODAY_CLEANING,
  HELP_JOB_TODAY_DAMAGE,
  HELP_JOB_TODAY_DURATION,
  HELP_JOB_TODAY_EQUIPMENT,
  HELP_JOB_TODAY_LATE,
  HELP_JOB_TODAY_LOST,
  HELP_JOB_TODAY_OTHER,
  HELP_JOB_TODAY_PRODUCTS,
  HELP_JOB_TODAY_STUCK,
  HELP_JOB_TODAY_REMOTE_KEY_TRANSFER,
  HELP_JOB_LATER_CLEANING,
  HELP_JOB_LATER_NEED_KEY,
  HELP_KEYS,
  HELP_SOMETHING_ELSE,
  HELP_WANTS_TO_LEAVE,
  HELP_RESCHEDULE_JOB,
  HELP_NNA_WANTS_TO_LEAVE,
  HELP_NNA_OTHER,
  CallRequestCategory,
  HELP_CALL_REQUEST_CATEGORIES,
  REMOVE_OLD_POSTCODE_JOBS,
  DO_NOT_REMOVE_POSTCODE_JOBS
};
