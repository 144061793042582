import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { IonDatetime, ModalController } from '@ionic/angular';

import { Date, dateToStr, InactivityReason, Worker, WorkerStatus } from '@housekeep/infra';

import { AnalyticsService } from 'services/analytics-service';
import { DeviceService } from 'services/device-service';
import { ErrorService } from 'services/error-service';
import {
  ContactFormData,
  HELP_NNA_OTHER,
  HELP_NNA_WANTS_TO_LEAVE,
  HelpService,
  LEAVE_REASON_SUBCATEGORIES
} from 'services/help-service';
import { TimeService } from 'services/time-service';
import { UserService } from 'services/user-service';

export const NNA_CHANGE_REASON_CHOICES: { key: InactivityReason; displayName: string }[] = [
  { key: InactivityReason.KeepExistingCustomers, displayName: 'Only want my existing customers' },
  { key: InactivityReason.WantsToLeave, displayName: 'I want to leave Housekeep' },
  { key: InactivityReason.Other, displayName: 'Other (please explain)' }
];

@Component({
  selector: 'hk-worker-status',
  templateUrl: './hk-worker-status.component.html',
  styleUrls: ['./hk-worker-status.component.scss']
})
export class HkWorkerStatusComponent implements OnInit, AfterViewInit {
  public readonly TODAY_STR = dateToStr(this.timeService.today());

  protected readonly LEAVE_REASON_SUBCATEGORIES = LEAVE_REASON_SUBCATEGORIES;
  protected readonly MODAL_NAME = 'Accepts New Accounts Modal';
  protected readonly NNA_CHANGE_REASON_CHOICES = NNA_CHANGE_REASON_CHOICES;
  protected readonly NNA_REASON = InactivityReason;
  protected readonly OTHER_PLACEHOLDER_MESSAGE: string = 'e.g."I don\'t want new customers because..."';
  protected readonly WANTS_TO_LEAVE_PLACEHOLDER_MESSAGE: string = 'e.g. "I want to leave Housekeep because..."';

  @ViewChildren(IonDatetime, { read: ElementRef }) datetimeElements: QueryList<ElementRef<HTMLIonDatetimeElement>> =
    new QueryList<ElementRef<HTMLIonDatetimeElement>>();

  @Input() currentWorkerStatus?: WorkerStatus;

  @Output() onUpdateWorker: EventEmitter<Worker> = new EventEmitter<Worker>();

  public currentNNAReason: InactivityReason;
  public isSubmitting = false;
  public selectedIsActive: boolean;

  public desiredWorkerStatus: WorkerStatus;

  // Variables for contact form
  public formData: ContactFormData = {
    category: null,
    confirmed: null,
    reason: null,
    description: null,
    startDate: null
  };

  constructor(
    protected analyticsService: AnalyticsService,
    protected deviceService: DeviceService,
    private errorService: ErrorService,
    private helpService: HelpService,
    protected modalController: ModalController,
    protected timeService: TimeService,
    private userService: UserService
  ) {}

  // Lifecycle hooks
  public ngOnInit(): void {
    this.selectedIsActive = this.currentWorkerStatus === WorkerStatus.Active;
    this.desiredWorkerStatus = this.currentWorkerStatus;
  }

  public ngAfterViewInit(): void {
    this.datetimeElements.forEach(elementRef => this.disableMonthYearSelector(elementRef));
    this.datetimeElements.changes.subscribe((next: QueryList<ElementRef<HTMLIonDatetimeElement>>) => {
      next.forEach(elementRef => {
        const disableInterval = setInterval(() => {
          const isDisabled = this.disableMonthYearSelector(elementRef);
          if (isDisabled) {
            clearInterval(disableInterval);
          }
        }, 50);
      });
    });
  }

  // Getters
  public get displayConfirmButton(): boolean {
    if (!this.currentNNAReason && !this.desiredWorkerStatus) {
      return false;
    }
    return this.currentWorkerStatus !== this.desiredWorkerStatus;
  }

  public get isCurrentlyActive(): boolean {
    return this.currentWorkerStatus === WorkerStatus.Active;
  }

  public get isCurrentlyNNA(): boolean {
    return this.currentWorkerStatus === WorkerStatus.NoNewAccounts;
  }

  public get isDesiredActive(): boolean {
    return this.desiredWorkerStatus === WorkerStatus.Active;
  }

  public get isDesiredNNA(): boolean {
    return this.desiredWorkerStatus === WorkerStatus.NoNewAccounts;
  }

  // Public methods
  public async handleUpdateNNA(): Promise<void> {
    this.isSubmitting = true;
    let reasonForInactivityDescription: string = null;
    const noNewAccountsDate: Date =
      this.desiredWorkerStatus === WorkerStatus.NoNewAccounts ? this.timeService.today() : null;

    if (this.currentNNAReason === InactivityReason.WantsToLeave) {
      this.formData.category = HELP_NNA_WANTS_TO_LEAVE;
      reasonForInactivityDescription =
        LEAVE_REASON_SUBCATEGORIES.find(option => option.key === this.formData.reason).displayName +
        ': "' +
        this.formData.description +
        '"';
    } else if (this.currentNNAReason === InactivityReason.Other) {
      this.formData.category = HELP_NNA_OTHER;
      this.formData.subcategory = null;
      reasonForInactivityDescription = this.formData.description;
    }

    try {
      const updatedWorker = await this.userService.updateWorkerStatus(
        this.desiredWorkerStatus,
        this.currentNNAReason,
        reasonForInactivityDescription,
        noNewAccountsDate
      );

      this.onUpdateWorker.emit(updatedWorker);

      if (
        this.desiredWorkerStatus === WorkerStatus.NoNewAccounts &&
        this.currentNNAReason !== InactivityReason.KeepExistingCustomers
      ) {
        await this.helpService.submitContactForm(this.formData);
      }

      this.isSubmitting = false;
    } catch (error) {
      this.errorService.showError(error);
      this.isSubmitting = false;
    }

    this.resetComponentDetails();
  }

  public onToggleChange(event: CustomEvent): void {
    this.desiredWorkerStatus = event.detail.checked ? WorkerStatus.Active : WorkerStatus.NoNewAccounts;
  }

  /**
   * Get whether the submit button for NNA categories that require additional
   * data should be disabled
   */
  public shouldDisableSubmitButton(contactForm: NgForm): boolean {
    if (this.isCurrentlyNNA && this.isDesiredActive) {
      return false;
    }

    if (
      this.isSubmitting ||
      !this.currentNNAReason ||
      (this.currentNNAReason !== this.NNA_REASON.WantsToLeave && !this.formData.confirmed)
    ) {
      return true;
    }
    return !contactForm.valid && this.currentNNAReason !== InactivityReason.KeepExistingCustomers;
  }

  // Private methods
  private disableMonthYearSelector(elementRef: ElementRef<HTMLIonDatetimeElement>): boolean {
    // Get the month/year selector in the datetime component
    const item = elementRef.nativeElement.shadowRoot.querySelector<HTMLIonItemElement>(
      '.calendar-month-year > ion-item'
    );
    if (item) {
      // Disable the item and remove the icon
      item.classList.add('item-disabled');
      item.style.opacity = '1.0';
      item.querySelector<HTMLIonIconElement>('ion-icon')?.remove();
      return true;
    }
    return false;
  }

  private resetComponentDetails(): void {
    this.currentNNAReason = null;
    this.formData = {
      category: null,
      confirmed: null,
      reason: null,
      description: null,
      startDate: null
    };
  }
}
