import { Injectable } from '@angular/core';

import { TwilioAuthService, ZendeskAuthService } from '@housekeep/hk-chat-client';

import { environment } from 'environment/environment';

import { Environment } from 'interfaces/environment';

import { RequestService } from './request-service';
import { UserService } from './user-service';

interface TokenResponse {
  token: string;
}

const TEN_MINUTES = 10 * 60 * 1000;

@Injectable({ providedIn: 'root' })
export class AppZendeskAuthService implements ZendeskAuthService {
  protected url = 'contact/request-customer-service-chat-token/';
  private environment: Environment;

  constructor(protected requestService: RequestService, protected userService: UserService) {
    this.environment = environment;
  }

  public getAccountKey(): string {
    return environment.ZENDESK_PUBLIC_ACCOUNT_KEY;
  }

  public async getToken(messaging = false): Promise<string> {
    await this.userService.getUser();
    const tokenResponse: TokenResponse = await this.requestService.get(this.url, {
      params: {
        messaging
      }
    });
    return tokenResponse?.token;
  }
}

@Injectable({ providedIn: 'root' })
export class AppTwilioAuthService implements TwilioAuthService {
  protected url = 'contact/request-chat-token/';

  constructor(protected requestService: RequestService, protected userService: UserService) {}

  public getToken(): Promise<string> {
    return this.userService.getUser().then(() => {
      return this.requestService
        .get(this.url)
        .then((tokenResponse: TokenResponse) => tokenResponse?.token)
        .catch(error => {
          if (error.status === 403) {
            // API is telling us that chat is not allowed. Meanwhile, the
            // chat service is waiting for a token. Stop repeated retries
            // by returning a promise that does not resolve:
            return new Promise<string>((resolve, reject) => {
              // After a long time out, reject the promise so that we will
              // eventually try again to check whether chat has been
              // enabled in the meantime.
              setTimeout(() => reject(), TEN_MINUTES);
            });
          } else {
            throw error;
          }
        });
    });
  }
}
